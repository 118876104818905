import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getProducts } from 'features/product/productActions';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { Product } from 'dto/product';
import { useHistory, useParams } from 'react-router-dom';
import { Row, usePagination, useTable } from 'react-table';
import { ProductsSearchForm } from 'routes/products/ProductsSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  productsFilterSelector,
  productsSelector,
} from 'features/product/productSelector';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { productsTableLoadingSelector } from 'features/loading/loadingSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import dtid from 'helpers/dtid';

interface ProductsTableProps {}

export const ProductsTable: FC<ProductsTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const product = useSelector(productsSelector);
  const owners = useSelector(businessEntitiesSelector);
  const filter = useSelector(productsFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(productsTableLoadingSelector);
  const data = useMemo(() => product?.items ?? [], [product]);
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const categoriesMap = useMemo(
    () => new Map(categoriesOptions.map(({ value, label }) => [value, label])),
    [categoriesOptions]
  );

  const link = useCallback(
    (row: Row<Product>) => `/products/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<Product>>(
    () => [
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row }: { row: Row<Product> }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {row.original.description}
          </Link>
        ),
      },
      {
        accessor: 'ownerId',
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ row }: { row: Row<Product> }) =>
          owners.find((owner) => owner.id === row.original.ownerId)?.name ??
          '—',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'categoryClassificationId',
        Header: <TransTableHead i18nKey="category" />,
        Cell: ({ row }: { row: Row<Product> }) =>
          categoriesMap.get(row.original.categoryClassificationId) ?? '',
      },
    ],
    [categoriesMap, link, owners]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (product) {
        const { limit = pageSize, offset } = product;
        return offset / limit;
      }
      return 0;
    },
    [product]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getProducts({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const table = useTable<Product>(
    {
      data,
      columns,
      pageCount: -1,
      total: product?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination,
    useRowActive
  );

  useTableRowHighlight(id, table);

  return (
    <>
      <ProductsSearchForm />
      <Divider />
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <Table
            data-testid={dtid.productsSearchResultsTable}
            table={table}
            getRowProps={(_, { row }) => ({
              sx: { cursor: 'pointer' },
              onClick: () => history.push(link(row)),
            })}
          />
        </SearchResult>
      </Loadable>
    </>
  );
};
