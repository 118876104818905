import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  renderRangeError,
  TableCellEditableTimeDuration,
  TableColumns,
} from '@fleet/shared';
import {
  DefaultAftersalesRule,
  DefaultAftersalesRulePayload,
} from 'dto/aftersales';
import { TransTableHead } from 'i18n/trans/table';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { renderToString } from 'react-dom/server';
import { TransError } from 'i18n/trans/error';
import _isNil from 'lodash/isNil';
import {
  convertNumberToTimeDuration,
  convertTimeDurationToNumber,
  TimeUnit,
} from '@fleet/shared/utils/timeDuration';
import { useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import {
  createOrUpdateProductAfterSalesRule,
  fetchProductAfterSalesRules,
} from 'features/product/productService';
import { AftersalesRulesTable } from 'routes/products/productAccordion/aftersalesRulesets/AftersalesRulesTable';
import {
  decimalToNumber,
  numberToDecimal,
} from 'routes/products/productAccordion/aftersalesRulesets/utils';

interface DefaultAftersalesRulesProps {
  productId: string;
  rulesetId: string;
}

type AfterSalesRuleTableItem = Omit<
  DefaultAftersalesRule,
  'activityTimeRestrictionInMinutes'
> & { timeDurationActivityTimeRestriction: Partial<Record<TimeUnit, number>> };

export const DefaultAftersalesRules: FC<DefaultAftersalesRulesProps> = ({
  productId,
  rulesetId,
}) => {
  const [rules, setRules] = useState<Array<AfterSalesRuleTableItem>>([]);
  const product = useSelector(currentProductSelector);
  const activityOptions = useClassificationOptions(
    ClassificationGroup.AFTERSALES_ACTIVITY
  );
  const activityTimeRestrictionOptions = useClassificationOptions(
    ClassificationGroup.AFTERSALES_ACTIVITY_TIME_RESTRICTION
  );

  const fetchRules = useCallback(async () => {
    const aftersalesRules =
      await fetchProductAfterSalesRules<DefaultAftersalesRule>(rulesetId);

    setRules(
      aftersalesRules.map(
        ({
          activityTimeRestrictionInMinutes,
          percentageKeptByClient,
          ...rest
        }) => {
          const { days, hours, minutes } = convertNumberToTimeDuration(
            activityTimeRestrictionInMinutes
          );

          return {
            ...rest,
            timeDurationActivityTimeRestriction: {
              days,
              hours,
              minutes,
            },
            percentageKeptByClient: decimalToNumber(percentageKeptByClient),
          };
        }
      )
    );
  }, [rulesetId]);

  useEffect(() => {
    if (productId && rulesetId) {
      fetchRules();
    }
  }, [productId, rulesetId, fetchRules]);

  const columns: TableColumns<AfterSalesRuleTableItem> = useMemo(
    () => [
      {
        id: 'activityType.id',
        accessor: ({ activityType }) => activityType?.id,
        Header: <TransTableHead i18nKey="activityType" />,
        type: 'select',
        editableProps: {
          options: activityOptions,
        },
      },
      {
        id: 'activityTimeRestrictionType.id',
        accessor: ({ activityTimeRestrictionType }) =>
          activityTimeRestrictionType?.id,
        Header: <TransTableHead i18nKey="timeRestrictionType" />,
        type: 'select',
        editableProps: {
          options: activityTimeRestrictionOptions,
        },
      },
      {
        accessor: 'timeDurationActivityTimeRestriction',
        Header: <TransTableHead i18nKey="timeRestrictionDuration" />,
        Cell: TableCellEditableTimeDuration,
        editableProps: {
          excludedUnits: [TimeUnit.SECONDS],
        },
        width: 180,
      },
      {
        id: 'percentageKeptByClient',
        accessor: 'percentageKeptByClient',
        type: 'text',
        Header: <TransTableHead i18nKey="percentageKeptByClient" />,
        width: 180,
        conditions: {
          disabled: {
            when: 'activityType.id',
            ['is!']: 'AFTERSALES_ACTIVITY.REFUND',
          },
        },
        editableProps: {
          type: 'number',
          required: false,
          validate: (value, row) => {
            if (
              row?.activityType?.id === 'AFTERSALES_ACTIVITY.REFUND' &&
              _isNil(value)
            ) {
              return renderToString(<TransError i18nKey="required" />);
            }

            return renderRangeError(value, 0, 100);
          },
        },
      },
    ],
    [activityOptions, activityTimeRestrictionOptions]
  );

  const handleRowUpdate = useCallback(
    async ({
      activityType,
      activityTimeRestrictionType,
      percentageKeptByClient,
      timeDurationActivityTimeRestriction,
      ...rest
    }: AfterSalesRuleTableItem) => {
      const payload: DefaultAftersalesRulePayload = {
        ...rest,
        percentageKeptByClient: numberToDecimal(percentageKeptByClient),
        activityTypeId: activityType.id,
        activityTimeRestrictionTypeId: activityTimeRestrictionType.id,
        activityTimeRestrictionInMinutes: convertTimeDurationToNumber(
          timeDurationActivityTimeRestriction
        ),
        objectType: product!.objectType,
      };

      await createOrUpdateProductAfterSalesRule(rulesetId, payload);
      fetchRules();
    },
    [product, rulesetId, fetchRules]
  );

  return (
    <AftersalesRulesTable
      data={rules}
      columns={columns}
      rulesetId={rulesetId}
      fetchData={fetchRules}
      updateRow={handleRowUpdate}
    />
  );
};
