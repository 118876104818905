import { FC, useCallback, useMemo } from 'react';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { TariffSearchForm } from 'routes/tariff/TariffSearchForm';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tariff } from 'dto/tariff';
import { TransTableHead } from 'i18n/trans/table';
import { useHistory, useParams } from 'react-router-dom';
import { joinDash } from 'helpers/array';
import { Row, usePagination, useTable } from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { useDispatch, useSelector } from 'store/utils';
import {
  tariffFilterSelector,
  tariffsSelector,
} from 'features/tariff/tariffSelectors';
import { getTariffs } from 'features/tariff/tariffActions';
import { tariffListLoadingSelector } from 'features/loading/loadingSelectors';

export const TariffTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const tariffs = useSelector(tariffsSelector);
  const filter = useSelector(tariffFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(tariffListLoadingSelector);
  const data = useMemo(() => tariffs?.items ?? [], [tariffs]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (tariffs) {
        const { limit = pageSize, offset } = tariffs;
        return offset / limit;
      }
      return 0;
    },
    [tariffs]
  );

  const link = useCallback(
    (row: Row<Tariff>) => `/tariff/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<Tariff>>(
    () => [
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        Cell: ({ row }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {row.original.code}
          </Link>
        ),
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
      {
        id: 'product',
        accessor: ({ product }) => product.name,
        Header: <TransTableHead i18nKey="product" />,
      },
      {
        accessor: 'carriers',
        Header: <TransTableHead i18nKey="carriers" />,
        Cell: ({ value }) => joinDash(value.map(({ name }) => name)),
      },
      {
        accessor: 'serviceCodes',
        Header: <TransTableHead i18nKey="serviceCodes" />,
        Cell: ({ value }) => joinDash(value),
      },
    ],
    [link]
  );
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      await dispatch(getTariffs({ ...filter, ...paginationParams }));
    },
    [dispatch, filter]
  );
  const initialState = useMemo(() => ({ pageSize: 10, activeRowId: id }), [id]);
  const getRowId = useCallback((row: Tariff) => row.id, []);

  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: tariffs?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive
  );

  useTableRowHighlight(id, table);

  return (
    <Loadable loading={loading}>
      <TariffSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </Loadable>
  );
};
