import { FC, useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  api,
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTableControls,
  useRowExpandToggle,
} from '@fleet/shared';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useExpanded, useRowSelect } from 'react-table';
import { AftersalesRuleset, AftersalesRulesetPayload } from 'dto/aftersales';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import { formatDate } from '@fleet/shared/utils/date';
import { getProduct } from 'features/product/productActions';
import { DefaultAftersalesRules } from 'routes/products/productAccordion/aftersalesRulesets/DefaultAftersalesRules';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TravelPassAftersalesRules } from 'routes/products/productAccordion/aftersalesRulesets/TravelPassAftersalesRules';
import { TransField } from 'i18n/trans/field';
import dtid from 'helpers/dtid';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality is't fully implemented in the BE yet

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflow: 'initial',
  },
  cell: {
    padding: '5px 0 !important' as string,
    verticalAlign: 'inherit',
  },
}));

export const ProductAftersalesRulesets: FC = () => {
  const product = useSelector(currentProductSelector);
  const dispatch = useDispatch();
  const data = useMemo(() => product?.aftersalesRulesets || [], [product]);
  const salesChannelOptions = useClassificationOptions(
    ClassificationGroup.SALES_CHANNEL
  );
  const styles = useStyles();

  const columns: TableColumns<AftersalesRuleset> = useMemo(
    () => [
      {
        accessor: 'aftersalesProfileId',
        Header: <TransTableHead i18nKey="afterSalesRulesetTemplate" />,
        Cell: ({ aftersalesProfileId }) => aftersalesProfileId ?? '-',
        width: 170,
      },
      {
        id: 'salesChannelTypes',
        accessor: ({ salesChannelTypes }) =>
          salesChannelTypes?.map(({ id }) => id),
        Header: <TransTableHead i18nKey="salesChannel" />,
        type: 'select',
        editableProps: {
          options: salesChannelOptions,
          multiple: true,
          parse: (value: Array<string>) => value.map((id) => ({ id })),
          format: (value: AftersalesRuleset['salesChannelTypes']) =>
            value && value.map(({ id }) => id),
        },
      },
      {
        accessor: 'isFeeAppliedOnlyForDownsell',
        Header: <TransTableHead i18nKey="feeAppliedForDownsell" />,
        type: 'checkbox',
        width: 170,
        editableProps: {
          required: false,
        },
      },
      {
        id: 'validityPeriod.from',
        accessor: ({ validityPeriod }) =>
          validityPeriod?.from ? formatDate(validityPeriod.from) : '',
        Header: <TransTableHead i18nKey="validFrom" />,
        type: 'date',
      },
      {
        id: 'validityPeriod.to',
        accessor: ({ validityPeriod }) =>
          validityPeriod?.to ? formatDate(validityPeriod.to) : '',
        Header: <TransTableHead i18nKey="validTo" />,
        type: 'date',
        conditions: {
          disabled: { when: 'validityPeriod.from', is: '' },
        },
        editableProps: {
          required: false,
        },
      },
    ],
    [salesChannelOptions]
  );

  const { form } = useForm<{ rows: Array<AftersalesRuleset> }>({
    initialValues: {
      rows: data,
    },
  });

  const handleRowUpdate = useCallback(
    async ({
      salesChannelTypes,
      isFeeAppliedOnlyForDownsell,
      ...rest
    }: AftersalesRuleset) => {
      const payload: AftersalesRulesetPayload = {
        ...rest,
        salesChannelTypeIds: salesChannelTypes.map(({ id }) => id),
        isFeeAppliedOnlyForDownsell: Boolean(isFeeAppliedOnlyForDownsell),
      };

      if (payload.id) {
        await api.put(`/aftersales/rulesets/${payload.id}`, payload);
      } else {
        await api.post(`/products/${product!.id}/aftersales/rulesets`, payload);
      }

      dispatch(getProduct(product!.id));
    },
    [product, dispatch]
  );

  const table = useFormTable<AftersalesRuleset>(
    {
      data,
      columns,
      form,
      autoResetExpanded: false,
      onRowUpdate: handleRowUpdate,
    },
    useExpanded,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowExpandToggle
  );

  const handleRowsDeleted = useCallback(
    async (values: Array<AftersalesRuleset>) => {
      await Promise.all(
        values.map(({ id }) => api.delete(`/aftersales/rulesets/${id}`))
      );
      dispatch(getProduct(product!.id));
    },
    [dispatch, product]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  return (
    <FormProvider form={form}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{ mb: 1, mt: 1 }}
      >
        {IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
          <Button
            startIcon={<Icon name="trash" size={20} />}
            variant="text"
            color="error"
            onClick={removeSelectedRows}
            disabled={Object.keys(table.state.selectedRowIds).length === 0}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
        )}
        <Button
          startIcon={<Icon name="plus" />}
          onClick={addRow}
          data-testid={dtid.productsAftersalesRulesetAddNew}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        data-testid={dtid.productsAftersalesRulesetTable}
        table={table}
        getHeaderGroupProps={{
          sx: { backgroundColor: 'common.white' },
        }}
        getRowProps={() => ({
          sx: { backgroundColor: 'common.white' },
          onClick: () => null,
        })}
        getSubRow={(row) => {
          if (!row.original.id) {
            return <span />;
          }

          if (product?.objectType === 'PRODUCT_CATEGORY.TRAVEL_PASS') {
            return (
              <TravelPassAftersalesRules
                productId={product!.id}
                rulesetId={row.original.id}
              />
            );
          }

          return (
            <DefaultAftersalesRules
              productId={product!.id}
              rulesetId={row.original.id}
            />
          );
        }}
        classes={{
          container: styles.tableContainer,
          cell: styles.cell,
        }}
        emptyLabel={<TransField i18nKey="noAftersalesRulesets" />}
      />
    </FormProvider>
  );
};
