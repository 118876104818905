import { TransKeys, createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  activationEventType: (
    <Trans
      i18nKey="field.activationEventType"
      defaults="Activation event type"
    />
  ),
  activationPeriod: (
    <Trans i18nKey="field.activationPeriod" defaults="Activation period" />
  ),
  afterSalesAllowed: (
    <Trans i18nKey="field.afterSalesAllowed" defaults="Aftersales allowed?" />
  ),
  ancillarySubtype: (
    <Trans i18nKey="field.ancillarySubtype" defaults="Ancillary subtype" />
  ),
  ancillaryType: (
    <Trans i18nKey="field.ancillaryType" defaults="Ancillary type" />
  ),
  arePromotionalDiscountsAllowed: (
    <Trans
      i18nKey="field.arePromotionalDiscountsAllowed"
      defaults="Promotional discounts allowed"
    />
  ),
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  category: <Trans i18nKey="field.category" defaults="Category" />,
  city: <Trans i18nKey="field.city" defaults="City" />,
  class: <Trans i18nKey="field.class" defaults="Class" />,
  code: <Trans i18nKey="field.code" defaults="Code" />,
  coefficient: (
    <Trans i18nKey="field.coefficient" defaults="Coefficient (multiple by)" />
  ),
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  currency: <Trans i18nKey="field.currency" defaults="Currency" />,
  dateCount: <Trans i18nKey="field.dateCount" defaults="Date #{{count}}" />,
  description: <Trans i18nKey="field.description" defaults="Description" />,
  discount: <Trans i18nKey="field.discount" defaults="Discount" />,
  distanceType: <Trans i18nKey="field.distanceType" defaults="Distance type" />,
  externalReference: (
    <Trans i18nKey="field.externalReference" defaults="External reference" />
  ),
  fareCategory: <Trans i18nKey="field.fareCategory" defaults="Fare category" />,
  from: <Trans i18nKey="field.from" defaults="From" />,
  group: <Trans i18nKey="field.group" defaults="Group" />,
  increment: (
    <Trans i18nKey="field.increment" defaults="Increment (add value)" />
  ),
  inventoryBucket: (
    <Trans i18nKey="field.inventoryBucket" defaults="Inventory bucket" />
  ),
  inventoryClass: (
    <Trans i18nKey="field.inventoryClass" defaults="Inventory class" />
  ),
  inventoryModel: (
    <Trans i18nKey="field.inventoryModel" defaults="Inventory model" />
  ),
  inventoryRank: (
    <Trans i18nKey="field.inventoryRank" defaults="Inventory rank" />
  ),
  isDiscountVoucherAllowed: (
    <Trans
      i18nKey="field.isDiscountVoucherAllowed"
      defaults="Voucher allowed"
    />
  ),
  isIntermediateStopsCheckInAllowed: (
    <Trans
      i18nKey="field.isIntermediateStopsCheckInAllowed"
      defaults="Check-in from intermediate stops enabled"
    />
  ),
  isMultiLegAllowed: (
    <Trans
      i18nKey="field.isMultiLegAllowed"
      defaults="Can be used for multi-leg journeys"
    />
  ),
  isThruFareAllowed: (
    <Trans i18nKey="field.isThruFareAllowed" defaults="Thru Fare allowed" />
  ),
  journeyDirection: (
    <Trans i18nKey="field.journeyDirection" defaults="Journey direction" />
  ),
  lineNumbers: <Trans i18nKey="field.lineNumbers" defaults="Line numbers" />,
  maxValue: <Trans i18nKey="field.maxValue" defaults="Max value" />,
  method: <Trans i18nKey="field.method" defaults="Method" />,
  minValue: <Trans i18nKey="field.minValue" defaults="Min value" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  noAftersalesRules: (
    <Trans
      i18nKey="field.noAftersalesRules"
      defaults="No aftersales rules added"
    />
  ),
  noAftersalesRulesets: (
    <Trans
      i18nKey="field.noAftersalesRulesets"
      defaults="No aftersales rulesets added"
    />
  ),
  noLocalizations: (
    <Trans i18nKey="field.noLocalizations" defaults="No localizations added" />
  ),
  noProductsInBundle: (
    <Trans
      i18nKey="field.noProductsInBundle"
      defaults="No products in bundle added"
    />
  ),
  noSubtypes: <Trans i18nKey="field.noSubtypes" defaults="No subtypes added" />,
  noSupplements: (
    <Trans i18nKey="field.noSupplements" defaults="No supplements added" />
  ),
  noTaxationRules: (
    <Trans i18nKey="field.noTaxationRules" defaults="No taxation rules added" />
  ),
  noTimeDependentConditions: (
    <Trans
      i18nKey="field.noTimeDependentConditions"
      defaults="No time dependent conditions"
    />
  ),
  numberOfUsages: (
    <Trans i18nKey="field.numberOfUsages" defaults="Number of usages" />
  ),
  orderNumber: <Trans i18nKey="field.orderNumber" defaults="Order number" />,
  organization: <Trans i18nKey="field.organization" defaults="Organization" />,
  owner: <Trans i18nKey="field.owner" defaults="Owner" />,
  passType: <Trans i18nKey="field.passType" defaults="Pass type" />,
  passengerCountFrom: (
    <Trans i18nKey="field.passengerCountFrom" defaults="Passenger count from" />
  ),
  passengerCountTo: (
    <Trans i18nKey="field.passengerCountTo" defaults="Passenger count to" />
  ),
  passengerTypeDiscount: (
    <Trans
      i18nKey="field.passengerTypeDiscount"
      defaults="Passenger type discount"
    />
  ),
  passengerTypes: (
    <Trans i18nKey="field.passengerTypes" defaults="Passenger types" />
  ),
  passengerTypesFieldCantBeEmpty: (
    <Trans
      i18nKey="field.passengerTypesFieldCantBeEmpty"
      defaults="Passenger types field cannot be empty"
    />
  ),
  primaryFareType: (
    <Trans i18nKey="field.primaryFareType" defaults="Primary fare type" />
  ),
  prmNeeds: <Trans i18nKey="field.prmNeeds" defaults="PRM needs" />,
  product: <Trans i18nKey="field.product" defaults="Product" />,
  productBundle: (
    <Trans i18nKey="field.productBundle" defaults="Product bundle" />
  ),
  promotionalDiscount: (
    <Trans
      i18nKey="field.promotionalDiscount"
      defaults="Promotional discount"
    />
  ),
  registeringAllowed: (
    <Trans i18nKey="field.registeringAllowed" defaults="Registering allowed" />
  ),
  required: <Trans i18nKey="field.required" defaults="Field is required" />,
  reservationOption: (
    <Trans i18nKey="field.reservationOption" defaults="Reservation option" />
  ),
  reservationProduct: (
    <Trans i18nKey="field.reservationProduct" defaults="Reservation product" />
  ),
  retailers: <Trans i18nKey="field.retailers" defaults="Retailers" />,
  rollingTravelPeriod: (
    <Trans
      i18nKey="field.rollingTravelPeriod"
      defaults="Rolling travel period (days)"
    />
  ),
  salesChannels: (
    <Trans i18nKey="field.salesChannels" defaults="Sales channels" />
  ),
  salesDateFrom: (
    <Trans i18nKey="field.salesDateFrom" defaults="Sales date from" />
  ),
  salesDateTo: <Trans i18nKey="field.salesDateTo" defaults="Sales date to" />,
  salesLevel: <Trans i18nKey="field.salesLevel" defaults="Sales level" />,
  service: <Trans i18nKey="field.service" defaults="Service" />,
  shortDescription: (
    <Trans i18nKey="field.shortDescription" defaults="Short description" />
  ),
  stayoverPeriodInDaysFrom: (
    <Trans
      i18nKey="field.stayoverPeriodInDaysFrom"
      defaults="Stayover period in days from"
    />
  ),
  stayoverPeriodInDaysTo: (
    <Trans
      i18nKey="field.stayoverPeriodInDaysTo"
      defaults="Stayover period in days to"
    />
  ),
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house, number" />
  ),
  subCategory: <Trans i18nKey="field.subCategory" defaults="Sub category" />,
  subGroup: <Trans i18nKey="field.subGroup" defaults="Sub group" />,
  to: <Trans i18nKey="field.to" defaults="To" />,
  travelPeriodType: (
    <Trans i18nKey="field.travelPeriodType" defaults="Travel period type" />
  ),
  usageType: <Trans i18nKey="field.usageType" defaults="Usage type" />,
  validFor: <Trans i18nKey="field.validFor" defaults="Valid for" />,
  validityPeriod: (
    <Trans i18nKey="field.validityPeriod" defaults="Validity period" />
  ),
  validityPeriodOfOneUsage: (
    <Trans
      i18nKey="field.validityPeriodOfOneUsage"
      defaults="Validity of one usage"
    />
  ),
});

export type TransFieldKeys = TransKeys<typeof TransField>;
