import {
  AddButton,
  Button,
  ConditionField,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { TariffSearchFilter } from 'dto/tariff';
import { TransHeader } from 'i18n/trans/header';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { getTariffs, setTariffFilter } from 'features/tariff/tariffActions';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Option } from '@fleet/shared/dto/option';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { fetchCarriers, fetchProducts } from 'features/tariff/tariffService';
import { tariffFilterSelector } from 'features/tariff/tariffSelectors';
import { OwnerField } from 'components/OwnerField';

export const TariffSearchForm: FC = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(tariffFilterSelector);
  const dispatch = useDispatch();

  const fareTypeOptions = useClassificationOptions(
    ClassificationGroup.FARE_TYPE
  );
  const [productOptions, setProductOptions] = useState<Array<Option<string>>>(
    []
  );
  const [carrierOptions, setCarrierOptions] = useState<Array<Option<string>>>(
    []
  );

  const onSubmit = useCallback(
    (values: TariffSearchFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getTariffs({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId, ...filter }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setTariffFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit, values } = useForm<TariffSearchFilter>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  useEffect(() => {
    (async () => {
      const [products, carriers] = await Promise.all([
        fetchProducts(values.ownerId),
        fetchCarriers(values.ownerId),
      ]);
      setProductOptions(makeClassificationOptions(products));
      setCarrierOptions(makeClassificationOptions(carriers));
    })();
  }, [dispatch, values.ownerId]);

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setTariffFilter({}));
  }, [form, dispatch]);

  return (
    <SearchForm
      title={<TransHeader i18nKey="tariff" />}
      action={
        <AddButton component={Link} to="/tariff/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2} alignItems="flex-end">
            <Grid item xs={1}>
              <TextField name="code" label={<TransField i18nKey="code" />} />
            </Grid>
            <Grid item xs={1}>
              <OwnerField />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="description"
                label={<TransField i18nKey="description" />}
              />
            </Grid>
            <Grid item xs={1}>
              <ConditionField when="ownerId" is={Boolean}>
                {(ownerId) => (
                  <SelectField
                    name="productId"
                    label={<TransField i18nKey="product" />}
                    options={productOptions}
                    disabled={!ownerId}
                  />
                )}
              </ConditionField>
            </Grid>
            <Grid item xs={1}>
              <ConditionField when="ownerId" is={Boolean}>
                {(ownerId) => (
                  <SelectField
                    name="carrierId"
                    label={<TransField i18nKey="carrier" />}
                    options={carrierOptions}
                    disabled={!ownerId}
                  />
                )}
              </ConditionField>
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="serviceCode"
                label={<TransField i18nKey="service" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="fareType"
                label={<TransField i18nKey="primaryFareType" />}
                options={fareTypeOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
              <Stack direction="row" spacing={2}>
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetFilters" />
                </Button>
                <Button icon="search" variant="contained" type="submit">
                  <TransButton i18nKey="search" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
