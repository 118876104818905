import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  TextField,
  Tooltip,
  formSubmit,
  useForm,
  useLoaded,
  useModal,
} from '@fleet/shared';
import { AncillaryTypeValues } from 'dto/ancillaryTypes';
import {
  createAncillaryType,
  deleteAncillaryTypes,
  getFullAncillaryType,
  setAncillaryType,
  updateAncillaryType,
} from 'features/ancillaryTypes/ancillaryTypesActions';
import { ancillaryTypeSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { ancillaryTypeLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import {
  ButtonGroup,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { OwnerField } from 'components/OwnerField';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { TransField } from 'i18n/trans/field';
import { AncillaryTypesAccordion } from 'routes/ancillaryTypes/Accordion/AncillaryTypesAccordion';
import dtid from 'helpers/dtid';

interface AncillaryTypesDetailsProps {}

export const AncillaryTypesDetails: FC<AncillaryTypesDetailsProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { open: isOpen, onOpen, onClose } = useModal();

  const { action, id } = useParams<{ action: string; id?: string }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );

  const ancillaryType = useSelector(ancillaryTypeSelector);
  const loading = useSelector(ancillaryTypeLoadingSelector);
  const loaded = useLoaded(loading);

  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const handleGoBack = useCallback(() => {
    history.replace('/ancillary-types');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        dispatch(setAncillaryType());
        handleGoBack();
      }
    },
    [dispatch, handleGoBack]
  );

  useEffect(() => {
    dispatch(setAncillaryType());
    if (isEditing && id) {
      dispatch(getFullAncillaryType(id)).unwrap().catch(handleGoBack);
    }

    return () => {
      dispatch(setAncillaryType());
    };
  }, [action, dispatch, handleGoBack, id, isEditing]);

  const initialValues = useMemo<AncillaryTypeValues>(
    () => ({
      ownerId: currentBusinessEntityId,
      ...(ancillaryType && {
        ...ancillaryType,
        ownerId: ancillaryType.owner.id,
      }),
    }),
    [ancillaryType, currentBusinessEntityId]
  );

  const onSubmit = useCallback(
    async (values: AncillaryTypeValues) =>
      formSubmit(async () => {
        const { id } = values;
        const data = await dispatch(
          (!id ? createAncillaryType : updateAncillaryType)(values)
        ).unwrap();

        if (!id) {
          alert.success(<TransAlert i18nKey="ancillaryTypeCreated" />);
          history.replace(`/ancillary-types/edit/${data.id}`);
        } else {
          alert.success(<TransAlert i18nKey="ancillaryTypeUpdated" />);
        }
      }),
    [alert, dispatch, history]
  );

  const { form, handleSubmit, dirty, submitting } = useForm({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteAncillaryTypes([id!])).unwrap();
    dispatch(setAncillaryType());
    alert.success(
      <TransAlert i18nKey="ancillaryTypeDeleted" values={{ count: 1 }} />
    );
    history.replace('/ancillary-types/');
  }, [alert, dispatch, history, id]);

  return (
    <DrawerForm
      open
      onClose={handleCloseEditForm}
      data-testid={dtid.ancillaryTypesDetailsDrawerForm}
    >
      <>
        <FormProvider form={form}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {isEditing ? (
                  ancillaryType?.name
                ) : loading ? (
                  <>&nbsp;</>
                ) : (
                  <TransSubtitle i18nKey="newAncillaryType" />
                )}
              </Typography>
            }
            action={
              <ButtonGroup>
                {isEditing && (
                  <>
                    <Button
                      variant="text"
                      color="error"
                      startIcon={<Icon name="trash" />}
                      onClick={onOpen}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <ConfirmDeleteModal
                      handleDelete={handleDelete}
                      title={
                        <TransModal
                          i18nKey="deleteAncillaryTypes"
                          values={{ count: 1 }}
                        />
                      }
                      description={
                        <TransModal
                          i18nKey="ancillaryTypesDeletionDescription"
                          values={{ count: 1 }}
                        />
                      }
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  </>
                )}
                <IconButton aria-label="close" onClick={handleGoBack}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </ButtonGroup>
            }
          />
          <CardContent component="form" onSubmit={handleSubmit}>
            <Grid container columns={1} spacing={2}>
              <Grid item xs={1}>
                <Grid container columns={18} spacing={2}>
                  <Grid item xs={3}>
                    <OwnerField disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="name"
                      label={<TransField i18nKey="name" />}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="code"
                      label={<TransField i18nKey="externalReference" />}
                      required
                    />
                  </Grid>
                  {isEditing && (
                    <Grid item xs={3}>
                      <TextField
                        name="orderNumber"
                        label={<TransField i18nKey="orderNumber" />}
                        disabled
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" flexWrap="nowrap">
                  <FormControl label="&nbsp;">
                    <Button
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      {...(!isEditing && { onClick: handleGoBack })}
                      {...(isEditing && {
                        onClick: handleReset,
                        disabled: !dirty,
                      })}
                    >
                      <TransButton
                        i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                      />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      variant="contained"
                      icon={isEditing ? 'check' : 'plus'}
                      type="submit"
                      disabled={!dirty || submitting}
                      data-testid={dtid.ancillaryTypesSubmit}
                    >
                      <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </FormProvider>
        {loaded && isEditing && ancillaryType && <AncillaryTypesAccordion />}
      </>
    </DrawerForm>
  );
};
