import { Pagination } from '@fleet/shared/dto/pagination';
import {
  InventoryParameters,
  PassengerGroup,
  PriceRules,
  SalesRules,
  Tariff,
  TariffLocalization,
  TariffSearchFilter,
  TariffSupplement,
  TimeDependentConditions,
  TimeDependentConditionsPayload,
} from 'dto/tariff';
import { createAsyncThunk } from 'store/utils';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { selectCurrentTariff } from 'features/tariff/tariffSelectors';
import { api } from '@fleet/shared';
import {
  GetTariffRTCResponse,
  UpdateTariffRTCResponseDto,
} from 'dto/api/product-definitions';
import { UpdateTariffRTCPayload } from 'dto/roundTripConditions';

export const setTariffFilter = createAction<Partial<TariffSearchFilter>>(
  'tariff/setTariffFilter'
);

export const clearTariffs = createAction('tariff/clearTariffs');

export const getTariffs = createAsyncThunk<
  Pagination<Tariff>,
  Partial<TariffSearchFilter> | undefined
>('tariff/getTariffs', async (values, { dispatch, getState }) => {
  values && dispatch(setTariffFilter(values));
  const { filter } = getState().tariff;
  return (
    await api.get(
      `/tariffs/search${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const setTariff = createAction<Tariff | undefined>('tariff/setTariff');

export const getTariffById = createAsyncThunk<Tariff, string>(
  'tariff/getTariff',
  async (id, { dispatch }) => {
    const tariff = (await api.get(`/tariffs/${id}`)).data;
    dispatch(setTariff(tariff));
    return tariff;
  }
);

export const createTariff = createAsyncThunk<Tariff, object>(
  'tariff/createTariff',
  async (payload, { rejectWithValue }) => {
    try {
      return (await api.post(`/tariffs`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateTariff = createAsyncThunk<Tariff, { id: string }>(
  'tariff/updateTariff',
  async ({ id, ...payload }) => (await api.put(`/tariffs/${id}`, payload)).data
);

export const copyTariffById = createAsyncThunk<string, string>(
  'tariff/copyTariffById',
  async (id) => (await api.post<{ id: string }>(`/tariffs/${id}/copy`)).data.id
);

export const getTariffSupplementProducts = createAsyncThunk<
  Array<{ id: string; description: string }>,
  number
>(
  'tariff/getTariffSupplementProducts',
  async (ownerId) =>
    (
      await api.get(
        `/products${qs.stringify(
          { ownerId, CategoryClassificationId: 'PRODUCT_CATEGORY.SUPPLEMENT' },
          { addQueryPrefix: true }
        )}`
      )
    ).data.items
);

export const getTariffTimeDependentConditions = createAsyncThunk<
  Array<TimeDependentConditions>,
  string
>(
  'tariff/getTariffTimeDependentConditions',
  async (id) =>
    (await api.get(`/tariffs/${id}/time-dependent-conditions`)).data.items
);

export const createOrUpdateTariffTimeDependentConditions = createAsyncThunk<
  void,
  TimeDependentConditionsPayload
>(
  'tariff/createOrUpdateTariffTimeDependentConditions',
  async ({ id, ...rest }, { getState }) =>
    (
      await (id ? api.put : api.post)(
        `/tariffs/${
          selectCurrentTariff(getState())!.id
        }/time-dependent-conditions${id ? `/${id}` : ''}`,
        rest
      )
    ).data
);

export const deleteTariffTimeDependentConditions = createAsyncThunk<
  void,
  Array<TimeDependentConditions>
>(
  'tariff/deleteTariffTimeDependentConditions',
  async (conditions, { getState }) => {
    await Promise.all(
      conditions.map(
        async ({ id }) =>
          await api.delete(
            `/tariffs/${
              getState().tariff.current?.id
            }/time-dependent-conditions/${id}`
          )
      )
    );
  }
);

export const getTariffSupplements = createAsyncThunk<
  Array<TariffSupplement>,
  string
>(
  'tariff/getTariffSupplements',
  async (id) => (await api.get(`/tariffs/${id}/supplements`)).data.items
);

export const getTariffPassengerGroup = createAsyncThunk<
  Array<PassengerGroup>,
  string
>(
  'tariff/getTariffPassengerGroup',
  async (id) => (await api.get(`/tariffs/${id}/passenger-groups`)).data.items
);

export const createOrUpdateTariffPassengerGroup = createAsyncThunk<
  void,
  PassengerGroup
>(
  'tariff/createOrUpdateTariffPassengerGroup',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/tariffs/${getState().tariff.current?.id}/passenger-groups${
        id ? `/${id}` : ''
      }`,
      payload
    )
);

export const deleteTariffPassengerGroup = createAsyncThunk<
  void,
  Array<PassengerGroup>
>('tariff/deleteTariffPassengerGroup', async (conditions, { getState }) => {
  await Promise.all(
    conditions.map(
      async ({ id }) =>
        await api.delete(
          `/tariffs/${getState().tariff.current?.id}/passenger-groups/${id}`
        )
    )
  );
});

export const getTariffPriceRules = createAsyncThunk<PriceRules, string>(
  'tariff/getTariffPriceRules',
  async (id) => (await api.get(`/tariffs/${id}/price-rules`)).data
);

export const updateTariffPriceRules = createAsyncThunk<PriceRules, PriceRules>(
  'tariff/updateTariffPriceRules',
  async (payload, { getState }) =>
    (
      await api.put(
        `/tariffs/${getState().tariff.current?.id}/price-rules`,
        payload
      )
    ).data
);

export const getTariffSalesRules = createAsyncThunk<SalesRules, string>(
  'tariff/getTariffSalesRules',
  async (id) => (await api.get(`/tariffs/${id}/sales-rules`)).data
);

export const updateTariffSalesRules = createAsyncThunk<
  SalesRules,
  { salesChannels: Array<{ id: string }>; retailers: Array<{ id: number }> }
>('tariff/updateTariffSalesRules', async (payload, { getState }) => {
  const currentTariff = selectCurrentTariff(getState());

  if (!currentTariff) {
    return;
  }

  return (await api.put(`/tariffs/${currentTariff.id}/sales-rules`, payload))
    .data;
});

export const getTariffInventoryParameters = createAsyncThunk<
  InventoryParameters,
  string
>(
  'tariff/getTariffInventoryParameters',
  async (tariffId) =>
    (await api.get(`/tariffs/${tariffId}/inventory-parameters`)).data
);

export const updateTariffInventoryParameters = createAsyncThunk<
  SalesRules,
  { inventoryModelId: string; inventoryModelBucketId: string }
>('tariff/updateTariffInventoryParameters', async (payload, { getState }) => {
  const currentTariff = selectCurrentTariff(getState());

  if (!currentTariff) {
    return;
  }

  return (
    await api.put(`/tariffs/${currentTariff.id}/inventory-parameters`, payload)
  ).data;
});

export const getTariffLocalizations = createAsyncThunk<
  Array<TariffLocalization>,
  string
>(
  'tariff/getTariffLocalizations',
  async (id) =>
    (
      await api.get<{ items: Array<TariffLocalization> }>(
        `/tariffs/${id}/localizations`
      )
    ).data.items
);

export const updateOrCreateTariffLocalization = createAsyncThunk<
  void,
  {
    tariffId: string;
    languageId: string;
    description: string;
    shortDescription?: string | null;
  }
>(
  'tariff/updateOrCreateTariffLocalization',
  async (
    { tariffId, languageId, description, shortDescription },
    { dispatch }
  ) => {
    await api.put<TariffLocalization>(
      `/tariffs/${tariffId}/localizations/${languageId}`,
      { description, shortDescription }
    );

    await dispatch(getTariffLocalizations(tariffId));
  }
);

export const deleteTariffLocalizations = createAsyncThunk<
  void,
  {
    id: string;
    languageIds: Array<string>;
  }
>(
  'product/deleteTariffLocalizations',
  async ({ id: tariffId, languageIds }) =>
    await api.delete(
      `/tariffs/${tariffId}/localizations${qs.stringify(
        { languageIds },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    )
);

export const updateTariffRoundTripConditions = createAsyncThunk<
  UpdateTariffRTCResponseDto,
  UpdateTariffRTCPayload
>(
  'tariff/updateOrCreateTariffRoundTripConditions',
  async ({ tariffId, ...payload }) => {
    return (
      await api.put<UpdateTariffRTCResponseDto>(
        `/tariffs/${tariffId}/round-trip-conditions`,
        payload
      )
    ).data;
  }
);

export const getTariffRoundTripConditions = createAsyncThunk<
  GetTariffRTCResponse,
  string
>('tariff/getTariffRoundTripConditions', async (tariffId) => {
  return (
    await api.get<GetTariffRTCResponse>(
      `/tariffs/${tariffId}/round-trip-conditions`
    )
  ).data;
});

export const deleteTariffRoundTripConditions = createAsyncThunk<void, string>(
  'tariff/deleteTariffRoundTripConditions',
  async (tariffId) =>
    await api.delete(`tariffs/${tariffId}/round-trip-conditions`)
);
