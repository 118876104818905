import {
  AncillaryType,
  AncillaryTypeDetailed,
  AncillaryTypeLocalization,
  AncillaryTypeLocalizationResponse,
  AncillaryTypeSubtype,
  AncillaryTypeSubtypeRequest,
  AncillaryTypeValues,
} from 'dto/ancillaryTypes';
import { createAsyncThunk } from 'store/utils';
import { api } from '@fleet/shared';
import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import {
  fetchAncillaryType,
  fetchAncillaryTypeSubtypes,
} from 'features/ancillaryTypes/ancillaryTypesService';

export const setAncillaryTypes = createAction<Array<AncillaryType>>(
  'ancillaryTypes/setAncillaryTypes'
);

export const getAncillaryTypes = createAsyncThunk<Array<AncillaryType>>(
  'ancillaryTypes/getAncillaryTypes',
  async (_, { getState }) => {
    const {
      common: { currentBusinessEntityId: ownerId },
    } = getState();

    return (
      await api.get<{ items: Array<AncillaryType> }>(
        `/ancillary-types${qs.stringify(
          { ownerId },
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data.items;
  }
);

export const reorderAncillaryTypes = createAsyncThunk<
  Array<AncillaryType>,
  Array<AncillaryType>
>(
  'ancillaryTypes/reorderAncillaryTypes',
  async (data, { getState, dispatch }) => {
    const {
      ancillaryTypes: { list: previousData },
    } = getState();

    dispatch(setAncillaryTypes(data));

    try {
      const updatedData = (
        await api.post<{ items: Array<AncillaryType> }>(
          '/ancillary-types/reorder',
          { ids: data.map(({ id }) => id) }
        )
      ).data.items;

      return updatedData;
    } catch (e) {
      return previousData;
    }
  }
);

export const setAncillaryType = createAction<
  Partial<AncillaryTypeDetailed> | undefined
>('ancillaryTypes/setAncillaryType');

export const getFullAncillaryType = createAsyncThunk<
  AncillaryTypeDetailed,
  AncillaryTypeDetailed['id']
>('ancillaryTypes/getFullAncillaryType', async (id) => {
  const [ancillaryType, subtypes] = await Promise.all([
    fetchAncillaryType(id),
    fetchAncillaryTypeSubtypes(id),
  ]);

  return { ...ancillaryType, subtypes };
});

export const getAncillaryType = createAsyncThunk<
  Omit<AncillaryTypeDetailed, 'subtypes'>,
  AncillaryTypeDetailed['id']
>('ancillaryTypes/getAncillaryType', async (id) => fetchAncillaryType(id));

export const getAncillaryTypeSubtypes = createAsyncThunk<
  Pick<AncillaryTypeDetailed, 'subtypes'>,
  AncillaryTypeDetailed['id']
>('ancillaryTypes/getAncillaryTypeSubtypes', async (id) => {
  const subtypes = await fetchAncillaryTypeSubtypes(id);

  return { subtypes };
});

export const createAncillaryType = createAsyncThunk<
  AncillaryType,
  Omit<AncillaryTypeValues, 'id'>
>('ancillaryTypes/createAncillaryType', async (payload, { dispatch }) => {
  const { data } = await api.post<AncillaryType>('ancillary-types', payload);
  await dispatch(getAncillaryTypes());

  return data;
});

export const updateAncillaryType = createAsyncThunk<
  AncillaryType,
  AncillaryTypeValues
>(
  'ancillaryTypes/updateAncillaryType',
  async ({ id, ...payload }, { dispatch }) => {
    const { data } = await api.put<AncillaryType>(
      `/ancillary-types/${id}`,
      payload
    );
    await dispatch(getAncillaryTypes());

    return data;
  }
);

export const deleteAncillaryTypes = createAsyncThunk<
  void,
  Array<AncillaryType['id']>
>('ancillaryTypes/deleteAncillaryTypes', async (ids) => {
  await api.delete(
    `/ancillary-types/bulk-delete${qs.stringify(
      { ids },
      {
        addQueryPrefix: true,
        skipNulls: true,
        arrayFormat: 'comma',
      }
    )}`
  );
});

export const updateOrCreateAncillaryTypeLocalizations = createAsyncThunk<
  AncillaryType,
  { id: AncillaryType['id']; payload: AncillaryTypeLocalization }
>(
  'ancillaryTypes/updateOrCreateAncillaryTypeLocalizations',
  async ({ id: typeId, payload: { languageId, ...payload } }, { dispatch }) => {
    await api.put<AncillaryTypeLocalizationResponse>(
      `/ancillary-types/${typeId}/localizations/${languageId}`,
      payload
    );

    const [ancillaryType] = await Promise.all([
      fetchAncillaryType(typeId),
      dispatch(getAncillaryTypes()),
    ]);

    return ancillaryType;
  }
);

export const deleteAncillaryTypeLocalizations = createAsyncThunk<
  void,
  {
    id: AncillaryType['id'];
    languageIds: Array<AncillaryTypeLocalization['languageId']>;
  }
>(
  'ancillaryTypes/deleteAncillaryTypeLocalizations',
  async ({ id: typeId, languageIds }) => {
    await api.delete(
      `/ancillary-types/${typeId}/localizations${qs.stringify(
        { languageIds },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    );
  }
);

export const updateOrCreateAncillaryTypeSubtypes = createAsyncThunk<
  Pick<AncillaryTypeDetailed, 'subtypes'>,
  { id: AncillaryType['id']; payload: AncillaryTypeSubtypeRequest }
>(
  'ancillaryTypes/updateOrCreateAncillaryTypeLocalizations',
  async ({ id: typeId, payload: { id, ...payload } }) => {
    await (id ? api.put : api.post)<AncillaryTypeSubtype>(
      `/ancillary-types/${typeId}/ancillary-subtypes${id ? `/${id}` : ''}`,
      payload
    );

    const subtypes = await fetchAncillaryTypeSubtypes(typeId);

    return { subtypes };
  }
);

export const deleteAncillaryTypeSubtypes = createAsyncThunk<
  void,
  { id: AncillaryType['id']; ids: Array<AncillaryTypeSubtype['id']> }
>('ancillaryTypes/deleteAncillaryTypeSubtypes', async ({ id: typeId, ids }) => {
  await api.delete(
    `/ancillary-types/${typeId}/ancillary-subtypes/bulk-delete${qs.stringify(
      { ids },
      {
        addQueryPrefix: true,
        skipNulls: true,
        arrayFormat: 'comma',
      }
    )}`
  );
});

export const reorderAncillaryTypeSubtypes = createAsyncThunk<
  Pick<AncillaryTypeDetailed, 'subtypes'>,
  { id: AncillaryType['id']; subtypes: Array<AncillaryTypeSubtype> }
>(
  'ancillaryTypes/reorderAncillaryTypeSubtypes',
  async ({ id: typeId, subtypes }, { getState, dispatch }) => {
    const {
      ancillaryTypes: { current: previousData },
    } = getState();

    dispatch(setAncillaryType({ subtypes }));

    try {
      const updatedSubtypes = (
        await api.post<{ items: Array<AncillaryTypeSubtype> }>(
          `/ancillary-types/${typeId}/ancillary-subtypes/reorder`,
          { ids: subtypes.map(({ id }) => id) }
        )
      ).data.items;

      return { subtypes: updatedSubtypes };
    } catch (e) {
      return { subtypes: previousData?.subtypes ?? [] };
    }
  }
);

export const updateOrCreateAncillaryTypeSubtypeLocalizations = createAsyncThunk<
  Pick<AncillaryTypeDetailed, 'subtypes'>,
  {
    id: AncillaryTypeSubtype['id'];
    typeId: AncillaryType['id'];
    payload: AncillaryTypeLocalization;
  }
>(
  'ancillaryTypes/updateOrCreateAncillaryTypeSubtypeLocalizations',
  async ({ id: subtypeId, typeId, payload: { languageId, ...payload } }) => {
    await api.put<AncillaryTypeLocalizationResponse>(
      `/ancillary-subtypes/${subtypeId}/localizations/${languageId}`,
      payload
    );

    const subtypes = await fetchAncillaryTypeSubtypes(typeId);

    return { subtypes };
  }
);

export const deleteAncillaryTypeSubtypeLocalizations = createAsyncThunk<
  void,
  {
    id: AncillaryTypeSubtype['id'];
    languageIds: Array<AncillaryTypeLocalization['languageId']>;
  }
>(
  'ancillaryTypes/deleteAncillaryTypeSubtypeLocalizations',
  async ({ id: subtypeId, languageIds }) => {
    await api.delete(
      `/ancillary-subtypes/${subtypeId}/localizations${qs.stringify(
        { languageIds },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    );
  }
);
